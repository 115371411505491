import styled from "@emotion/styled";
import { useState } from "react";
import { useTheme } from "emotion-theming";
import { aggregations } from "../../../../../utils/constants/constants";
import IOButton from "../../../../../UI/Form/Button/IOButton";
import { scrollbarDe } from "../../../../../styles/styledSnippets";
import { getRestOverrides } from "../../../utils/tableValidation";
import ColumnMonster from "./ColumnMonster";
import Settings from "./Settings";
import Tabs from "./Tabs";
import Styles from "./Styles/Styles";
import Flex from "../../../../../UI/Flex/Flex";
import { getRidOfAggregation } from "../../../../../charts/TableView/Elements/EditableMenu";

const Container = styled.div(
  ({ theme }) => `
  padding: 10px;
  text-align: left;
  height: 97%;
  overflow-y: auto;
  ${scrollbarDe(theme)};
`
);

export default function ColumnSettings({
  setChartState,
  selectedColumn,
  overrides,
  setSelectedColumn,
  coloredColumns,
  dynamicShadeBackgrounds,
  dynamicShadeBackgroundsBaseZero,
  generalColumnsWidth,
  dynamicColumnsWidth,
  maxColumnsWidth,
  renderAsImageKeys,
  removeColumn,
  removeDynamicSubTitleColumn,
  query,
  navigationState,
  rawRows,
  subTitles,
  fields,
  staticGroupingKeys,
  dynamicSubTitleKeys,
  chart,
  previewChart,
  immutableChart,
  isParameterized,
}) {
  const theme = useTheme();
  const [onStyles, setOnStyles] = useState();

  if (!selectedColumn) {
    return (
      <Container>
        <ColumnMonster />
      </Container>
    );
  }

  const { column, subIndex, index, name, aggregation, ops } = selectedColumn;
  const formulaType = column.includes("fn::");
  const openMathFormulaType = column.includes("omf::");
  const separateNewFormula = column.includes("snf::");

  function refreshOverrides(rest) {
    const filtered = overrides.filter((o) => rest.includes(o.name));
    return [...filtered, fields.find((f) => f.name === name)];
  }

  const updateOverrides = (newSubTitles) => {
    const rest = getRestOverrides(newSubTitles, overrides);
    const newOverrides = formulaType
      ? refreshOverrides(rest)
      : overrides.filter((o) => {
          if (o.name === name) {
            return rest.includes(name);
          }
          return true;
        });

    setChartState({ ...chart, overrides: newOverrides });
  };

  function getPrefix() {
    return (
      aggregations.find((aggr) => aggr.value === aggregation)?.prefix || ""
    );
  }

  function updateColumn(column) {
    setSelectedColumn({ ...selectedColumn, subIndex, column });
  }

  function updateOverride() {
    return chart.overrides.map((override) =>
      override.name === name ? { name } : override
    );
  }

  function resetColumnOverride() {
    const overrides = updateOverride();
    setChartState({
      ...chart,
      overrides: overrides,
      subTitles: chart.subTitles.map((subTitle, i) => {
        return subTitle.map((sub, j) =>
          i === subIndex && j === index && column === sub
            ? getRidOfAggregation(sub, overrides)
            : sub
        );
      }),
    });

    setSelectedColumn({
      column: getRidOfAggregation(column, overrides),
      name,
      subIndex,
      index,
    });
  }

  const switchColumnType = () => {
    const prefix = getPrefix();

    const newSub = prefix + (ops?.alias || name || "...");
    if (!dynamicSubTitleKeys) {
      const newSubTitles = subTitles.map((subTitle, i) =>
        subTitle.map((sub, j) =>
          (sub === column || sub === ops?.alias) &&
          i === subIndex &&
          j === index
            ? newSub
            : sub
        )
      );
      updateOverrides(newSubTitles);

      setChartState({
        ...chart,
        subTitles: newSubTitles,
      });
    } else {
      setChartState({
        ...chart,
        dynamicSubTitleKeys: chart.dynamicSubTitleKeys.map((key) =>
          key === column ? newSub : key
        ),
      });
    }

    updateColumn(newSub);
  };

  const switchToOpenMathColumnType = () => {
    const newSub = `omf::NONE::...`;
    setSelectedColumn({ index, subIndex, column: newSub, name: "" });
    setChartState({
      ...chart,
      subTitles: subTitles.map((subTitle, i) =>
        subTitle.map((sub, j) => (i === subIndex && j === index ? newSub : sub))
      ),
    });
  };

  const switchToSeparateNewColumnType = () => {
    // keep label for deprecated formula type
    const array = column
      .replace("ReverseColor::", "")
      .replace("ROUNDED::", "")
      .replace("BORDERED::", "")
      .split("::");

    const label = array[array.length - 1];
    const newSub = `snf::NONE::NONE::${label}`;

    setSelectedColumn({ index, subIndex, column: newSub, name: "" });
    setChartState({
      ...chart,
      subTitles: subTitles.map((subTitle, i) =>
        subTitle.map((sub, j) => (i === subIndex && j === index ? newSub : sub))
      ),
    });
  };

  const columnProps = {
    chart,
    overrides,
    selectedColumn,
    setChartState,
    setSelectedColumn,
    theme,
    query,
    navigationState,
    fields,
    staticGroupingKeys,
    subTitles,
    dynamicSubTitleKeys,
    formulaType,
    openMathFormulaType,
    separateNewFormula,
    switchColumnType,
    switchToOpenMathColumnType,
    switchToSeparateNewColumnType,
    rawRows,
    coloredColumns,
    dynamicShadeBackgrounds,
    dynamicShadeBackgroundsBaseZero,
    generalColumnsWidth,
    dynamicColumnsWidth,
    maxColumnsWidth,
    renderAsImageKeys,
    previewChart,
    immutableChart,
    isParameterized,
  };

  const onRemove = () => {
    if (chart.dynamicSubTitleKeys) {
      return removeDynamicSubTitleColumn(column);
    }
    removeColumn(chart, subIndex, index);
  };

  return (
    <Container>
      <Tabs onStyles={onStyles} setOnStyles={setOnStyles} />
      {onStyles ? <Styles {...columnProps} /> : <Settings {...columnProps} />}
      <br />
      <Flex justifyContent="space-around">
        <IOButton
          onClick={resetColumnOverride}
          style={{ padding: "8px 12px" }}
          title="Reset Override will drop mappings and aggregation for current column"
          cy="reset-column-override"
        >
          Reset Override
        </IOButton>
        <IOButton
          error
          onClick={onRemove}
          style={{ padding: "8px 12px" }}
          cy="remove-column"
        >
          Remove Column
        </IOButton>
      </Flex>
    </Container>
  );
}
