import React from "react";
import FilterGroup from "./FilterGroup";
import DateType from "./DateType";
import MenuDateSelect from "./MenuDateSelect";
import PropTypes from "prop-types";
import ToDateFilters from "./ToDateFilters";
import {
  DATE_FILTER_MODE_CUSTOM,
  DATE_FILTER_MODE_NONE,
  DATE_FILTER_MODE_RANGE_ONLY,
} from "../../utils/constants/constants";

function DateFilters(props) {
  const {
    dateConfig,
    dualPage,
    dualType,
    domain,
    showDateFilters,
    userSettings,
    additionalTheme,
    currentTabId,
  } = props;

  const filterMode =
    dateConfig?.dateFiltersConfig?.filterMode ?? DATE_FILTER_MODE_NONE;

  const rangeComponent = (
    <FilterGroup name="Date Range" open cy="left-menu-date-range">
      <MenuDateSelect
        show={showDateFilters}
        // show={dateConfig.showDateFilters}
        dateFiltersConfig={dateConfig.dateFiltersConfig}
        onChange={dateConfig.onDateChangeHandler}
        startDate={dateConfig.startDate}
        endDate={dateConfig.endDate}
      />
    </FilterGroup>
  );

  const toDateComponent = (
    <ToDateFilters
      setSelectedDateType={dateConfig.setSelectedDateType} // pass this down with date object
      selectedDateType={dateConfig.selectedDateType}
      startDate={dateConfig.startDate}
      endDate={dateConfig.endDate}
      changeDateValue={dateConfig.changeDateValue}
      setDate={dateConfig.setDate}
      recentMonthsChange={dateConfig.recentMonthsChange}
      dateFiltersConfig={dateConfig.dateFiltersConfig}
      domain={domain}
      userSettings={userSettings}
      dateConfig={dateConfig}
      additionalTheme={additionalTheme}
      onDateTypeChangeHandler={dateConfig.onDateTypeChangeHandler}
      currentTabId={currentTabId}
    />
  );

  const whatToRender = () => {
    if (filterMode === DATE_FILTER_MODE_RANGE_ONLY) {
      return rangeComponent;
    }

    if (filterMode === DATE_FILTER_MODE_CUSTOM) {
      if (dualType && !dualPage) {
        return rangeComponent;
      }
      return toDateComponent;
    }
  };

  const aggregationComponent =
    dateConfig.dateFiltersConfig?.dynamicAggregationEnabled &&
    dateConfig.dateFiltersConfig.dynamicAggregationValues?.length ? (
      <FilterGroup name="Date Type" open cy="left-menu-date-term">
        <DateType
          aggregation={dateConfig.dateFiltersConfig.dynamicAggregationValues}
          term={dateConfig.term}
          onChange={dateConfig.onDateTermHandler}
        />
      </FilterGroup>
    ) : null;

  return (
    <>
      {whatToRender()}
      {!dualPage && aggregationComponent}
    </>
  );
}

DateFilters.propTypes = {
  dateFiltersConfig: PropTypes.object,
};

export default React.memo(DateFilters);
