import { cloneDeep } from "lodash-es";
import Select from "../../../UI/Form/Select/Select";

export const styles = {
  CIRCLES_ONLY: "circlesOnly",
  DASHED: "dashed",
  DASHED_ONLY: "dashedOnly",
  LINE_ONLY: "lineOnly",
};

const { DASHED, DASHED_ONLY, LINE_ONLY, CIRCLES_ONLY } = styles;

export const dashedStyles = [DASHED, DASHED_ONLY];
export const withoutCircleStyles = [DASHED_ONLY, LINE_ONLY];

const styleOptions = [
  {
    value: DASHED,
    label: "Dashed",
  },
  {
    value: LINE_ONLY,
    label: "Line Only",
  },
  {
    value: DASHED_ONLY,
    label: "Dashed Only",
  },
  {
    value: CIRCLES_ONLY,
    label: "Circles Only",
  },
];

export default function LineStyles(props) {
  const { settings, valueIndex, setChangesDraft } = props;
  const valueKeys = settings("valueKeys") ?? [];

  const currentKey = valueKeys[valueIndex] ?? {};
  const shape = currentKey.extras?.shape === "line";

  if (!shape) {
    return null;
  }

  const removeFalsy = (object) => {
    return Object.entries(object).reduce(
      (a, [k, v]) => (!v ? a : ((a[k] = v), a)),
      {}
    );
  };

  function setLineStyle(option) {
    const nextValueKeys = cloneDeep(valueKeys);

    nextValueKeys[valueIndex].extras = removeFalsy({
      ...currentKey.extras,
      style: option?.value,
    });

    setChangesDraft((draft) => (draft.valueKeys = nextValueKeys));
  }

  return (
    <Select
      options={styleOptions}
      simpleValue={currentKey.extras.style}
      onChange={setLineStyle}
      label="Style"
      cy="y-axis-style-select"
      isClearable
    />
  );
}
