import { useEffect, useState } from "react";

// this function is like a redux store but only for local states of non grouped pages
export default function useToDateFiltersLocalState(tabChanged, defaultValue) {
  const [localState, setLocalState] = useState(defaultValue);

  // update local value if tab changed, need only for pages without group
  useEffect(() => {
    if (tabChanged) {
      setLocalState(defaultValue);
    }
  }, [defaultValue, tabChanged]);

  return {
    localState,
    setLocalState,
  };
}
