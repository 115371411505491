import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import QuarterRange from "./QuarterRange";
import SingleDay from "./SingleDay";
import CustomRange from "./CustomRange";
import FutureDaysAndEarlier from "./FutureDaysAndEarlier";
import RollingDates from "./RollingDates";
import { rollingList } from "../../../utils/constants/constants";

const Container = styled.div(
  ({ theme }) => `
    margin-top: 24px;
    left: 6px;
    & > span {
        color: ${theme.menuPrimary ? theme.textOnPrimary : theme.text.primary};
        padding: 0px 12px;
        font-size: 0.7em;
        margin-left: 10px;
        text-transform: uppercase;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
`
);

export default function CustomDateFilters(props) {
  const theme = useTheme();
  const rollingDates = props.dateFiltersConfig?.rollingDates ?? {};

  // Helper function to create reusable date filter components
  const createRollingDateElement = ({ type, toDateType, label }) => (
    <RollingDates
      selectProps={type ? props.selectProps : null}
      type={type}
      recentMonthsChange={props.recentMonthsChange}
      toDateType={toDateType}
      label={label}
      defaultRollingDate={rollingDates[toDateType]}
      defaultRollingDateCount={rollingDates[toDateType]?.count ?? 1}
      {...props}
    />
  );

  // Predefined date filter components
  const defaultDateFilters = {
    sd: <SingleDay Container={Container} theme={theme} {...props} />,
    cr: (
      <Container>
        <CustomRange {...props} />
      </Container>
    ),
    fiscalQuarterRange: <QuarterRange {...props} />,
    past3YearsToNextDays: (
      <Container>
        <FutureDaysAndEarlier {...props} />
      </Container>
    ),
  };

  // Adding rolling list items dynamically
  const customElements = rollingList.reduce((acc, curr) => {
    acc[curr.key] = createRollingDateElement(curr);
    return acc;
  }, defaultDateFilters);

  // Return the matching date filter component or null if not found
  return customElements[props.dateType] ?? null;
}
