import React from "react";
import { assignAsKey, groupUnder, pipe } from "../../utils/func";
import { toDateType } from "../../utils/dates/dateFunc";
import { hasAttr } from "../LineBarChart/LineBarChartVisualization";
import YAxis from "../Axes/YAxis";
import SpringLine from "../MultipleLineChart/SpringLine";
import PositionCirclesPlaced from "../PositionCircle/PositionCirclesPlaced";
import { getColor } from "../BaseChart/mapColorConfig";
import { mapYAxisLabel } from "../BaseChart/mapAxisSettings";
import { chartTypeKeyMap } from "../../utils/constants/chartConstants";
import {
  dashedStyles,
  styles,
  withoutCircleStyles,
} from "../../Editors/BlockEdit/GuiAxisSettings/LineStyles";

export default function StackedLinesVisualization(props) {
  const {
    lineKeys,
    circleKeys = [],
    initialData,
    xKey,
    dashedKeys,
    width,
    yRightAxisFormat,
    yRightAxisTypeLabel,
    setTooltip,
    meta,
    xScale,
    yScale,
    hideAxis,
    colors,
    xInner,
    stackedBarAllowance,
  } = props;

  const combinedYKeys = [...lineKeys.map((k) => k.alias), ...circleKeys];
  if (!lineKeys.length) return null;

  // @todo this all needs to be handled in Label Component
  const xMetaMatch = meta?.fields.find((f) => {
    return f.name === xKey || f.alias === xKey;
  });
  const xLabel = xMetaMatch?.label;
  const yLabel = meta?.fields.find(
    (f) => f.name === lineKeys[0]?.alias || f.alias === lineKeys[0]?.alias
  )?.label;

  function buildInitialGroups(keys) {
    return pipe(
      assignAsKey(xKey, "xValue"),
      toDateType("xValue", null, null, true),
      assignAsKey(keys[0].alias, "value"),
      groupUnder(keys, [], xKey)
    )(initialData);
  }

  const lineKeyAliases = lineKeys.map((l) => l.alias);

  const initialLineGroupsPre = buildInitialGroups(lineKeyAliases);

  const initialLineGroups = initialLineGroupsPre.map((line, index) => {
    const match = lineKeys.find((l) => l.alias === line.key);
    return {
      ...line,
      colorIndex: match.colorIndex,
      style: lineKeys[index]?.style,
    };
  }); // @todo Colors are no longer handled here

  const lineGroups = initialLineGroups.map((line) => ({
    ...line,
    curved: hasAttr(lineKeyAliases, line.key),
    dashed: hasAttr(dashedKeys, line.key) || dashedStyles.includes(line.style),
    values: line.values.map((v) => ({ ...v, value: +v.value })),
  }));

  const { rightLabel } = mapYAxisLabel(
    chartTypeKeyMap.StackedBars,
    lineKeys,
    yRightAxisTypeLabel,
    true
  );

  return (
    <>
      {!hideAxis && combinedYKeys.length > 0 && (
        <g data-cy="right-y-axis">
          <YAxis
            {...props}
            yScale={yScale}
            yTicksCount={props.yTicksCount}
            yTicksColor={props.yTicksColor}
            hideYAxisLine={props.hideYAxisLine}
            hideYAxisTicks={props.hideYAxisTicks}
            yAxisGrid={props.yAxisGrid}
            yAxisGridColor={props.yAxisGridColor}
            width={width}
            yAxisFormat={yRightAxisFormat}
            translateAxis={width + 15}
            translateRightLabel={width + 48}
            yAxisTypeLabel={rightLabel}
            hideYAxisLabel={props.hideYRightAxisLabel}
            orient="right"
          />
        </g>
      )}

      {lineKeys.length > 0 &&
        lineGroups.map((section, index) =>
          section.style === styles.CIRCLES_ONLY ? null : (
            <g
              data-cy="multiple-lines-line"
              key={section.key + index}
              style={{ zIndex: 1 }}
            >
              <SpringLine
                width={width}
                values={section.values}
                stroke={getColor(colors, section.key, null, true)}
                style={section.dashed ? { strokeDasharray: "5, 8" } : {}}
                strokeWidth={1}
                fill="none"
                x={xScale}
                y={yScale}
                xOffset={
                  xScale.bandwidth() / 2 +
                  (stackedBarAllowance ? xInner.bandwidth() / 2 : 0)
                }
              />
            </g>
          )
        )}

      {lineKeys.length > 0 &&
        lineGroups.map((section, index) =>
          withoutCircleStyles.includes(section.style) ? null : (
            <g data-cy="circles" key={section.key + index}>
              <PositionCirclesPlaced
                data={initialData}
                yScale={yScale}
                xScale={xScale}
                xKey={xKey}
                yKey={section.key}
                xLabel={xLabel}
                yLabel={yLabel}
                color={getColor(colors, section.key, null)}
                setTooltip={setTooltip}
                borderSize={0.5}
                radius={4.5}
                meta={meta}
                noDate
                xInner={xInner}
                skipTooltipConfig
                stackedBarAllowance={stackedBarAllowance} // this is wrong
              />
            </g>
          )
        )}
    </>
  );
}
