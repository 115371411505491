import React, { useRef, useCallback } from "react";
import Select from "../../UI/Form/Select/Select";
import FilterGroup from "./FilterGroup";
import CustomDateFilters from "./ToDateFilters/CustomDateFilters";
import {
  getHasParameter,
  getUserSelectedDateOption,
} from "./ToDateFilters/customDateUtils";
import { InfoCircleTooltip } from "../../UI/SimpleTooltip/SimpleTooltip";
import useToDateFilters from "./ToDateFilters/useToDateFilters";

// Custom range date filter.
export default function ToDateFilters(props) {
  const {
    selectedDateType,
    setSelectedDateType,
    recentMonthsChange,
    dropDateGroup,
    onDateTypeChangeHandler,
    dateFiltersConfig,
    currentTabId,
  } = props;

  const typeRef = useRef(null);
  const recentRef = useRef(null);

  const {
    mainOptions,
    mainSelection,
    secondaryOptions,
    dateType,
    getSecondaryDropdownProperties,
    setSecondaryDropdownOption,
    formatOptionLabel,
    options,
    currentNoneOption,
    RecentPeriods,
    recentSelected, // this is local state of secondary dropdown option
    setRecentSelected,
    saveStickyDatesToStore,
    setSpecialDateRanges,
  } = useToDateFilters({ ...props, activeTab: { uuid: currentTabId } });

  const getPosition = useCallback(({ current }) => {
    if (!current) {
      return "auto";
    }

    return window.innerHeight - current?.offsetTop + current?.offsetHeight > 250
      ? "bottom"
      : "top";
  }, []);

  function handleMainTypeSelection(type) {
    // on date presets do not work as date filter, work as menu filter
    if (dropDateGroup) {
      return setSelectedDateType(type);
    }

    const { defaultOption } = getSecondaryDropdownProperties(type);
    saveStickyDatesToStore(type, defaultOption);

    if (getHasParameter(type)) {
      setSelectedDateType(type);
      setSecondaryDropdownOption(type);
      setSpecialDateRanges(type);
    } else {
      onDateTypeChangeHandler(type);
    }
  }

  function onRecentChange(option, updateLocalState) {
    // do not update local state if function called from special types
    if (updateLocalState) {
      setRecentSelected(option); // this is local state of secondary dropdown option
    }

    saveStickyDatesToStore(selectedDateType, option);
    recentMonthsChange(option, selectedDateType);
  }

  const mainSelectContainer = mainOptions.length ? (
    <div ref={typeRef}>
      <Select
        options={currentNoneOption ? [currentNoneOption, ...options] : options}
        classNamePrefix="react-select"
        onChange={handleMainTypeSelection}
        value={mainSelection}
        placeholder="Select date filter type..."
        menuPlacement={getPosition(typeRef)}
        menuHeight="fit-content"
        cy="select-custom-date"
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  ) : (
    <div>
      <em>No date filter presets available</em>{" "}
      <InfoCircleTooltip tooltip="You can add date filter presets in Site Configuration -> Date Filters." />
    </div>
  );

  const innerFilters = (
    <>
      {/*Main Option Select*/}
      {mainSelectContainer}

      {/*Second Option Selection*/}
      {secondaryOptions[dateType] ? (
        <RecentPeriods ref={recentRef}>
          <Select
            options={secondaryOptions[dateType].options}
            onChange={(option) => onRecentChange(option, true)}
            getOptionValue={(o) => o.from}
            placeholder={secondaryOptions[dateType].placeholder}
            value={recentSelected}
            menuPlacement={getPosition(recentRef)}
          />
        </RecentPeriods>
      ) : null}

      {/*Special Date Types*/}
      <CustomDateFilters
        dateType={dateType}
        RecentPeriods={RecentPeriods}
        getPosition={getPosition}
        recentMonthsChange={onRecentChange}
        selectProps={secondaryOptions}
        getUserSelectedDateOption={getUserSelectedDateOption}
        saveStickyDatesToStore={saveStickyDatesToStore}
        activeTabUuid={currentTabId}
        {...props}
      />
    </>
  );

  if (dropDateGroup) {
    return innerFilters;
  }

  return (
    <FilterGroup
      name={dateFiltersConfig?.displayName || "Dates"}
      open
      cy="left-menu-to-current-range"
    >
      {innerFilters}
    </FilterGroup>
  );
}
